.best-offer-image-col {
    padding: 25px !important;
}

.best-offer-image {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .best-offer-image {

    }
}
