.best-offer-details-container {
    color: white;
}

.best-offer-details-title {
    font-size: 24px;
    font-weight: bold;
}

.best-offer-details-small-text {
    font-size: 12px;
    margin-top: 10px;
}

.best-offer-details-actual-price {
    font-size: 24px;
    font-weight: bold;
}

.best-offer-details-typical-price {
    font-size: 16px;
    font-weight: bold;
}

.best-offer-details-line {
    background-color: grey;
}

.best-offer-details-difference {
    color: #fdbd39;
}
