html, body, #root {
  height: 100%;
  background-color: #121212 !important;
  overflow-x: hidden;
}

@font-face {
  font-family: 'HelveticaNeueLTPro-XBlkCn';
  src: local('HelveticaNeueLTPro-XBlkCn'), url(./assets/fonts/HelveticaNeueLTPro-XBlkCn.otf) format('opentype');
}

@font-face {
  font-family: 'HelveticaNeueLTPro-Roman';
  src: local('HelveticaNeueLTPro-Roman'), url(./assets/fonts/HelveticaNeueLTPro-Roman.otf) format('opentype');
}

body {
  margin: 0;
  font-family: 'Roboto', 'Bebas Neue', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover {
  text-decoration: none !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-top25 {
  margin-top: 25px;
}

.margin-top75 {
  margin-top: 75px;
}

.margin-bottom75 {
  margin-bottom: 75px;
}

.padding-top25 {
  padding-top: 25px;
}

.padding-right25 {
  padding-right: 25px !important;
}
