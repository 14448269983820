.newsletter-title {
    color: white;
    font-size: 40px;
    text-align: center;
}

.newsletter-subtitle {
    color: white;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
}

.newsletter-choice, .newsletter-choice > div, .newsletter-category, .newsletter-agreement {
    color: white;
    font-weight: bold;
    font-size: 14px;
}

.newsletter-choice > div, .newsletter-agreement {
    display: flex;
    align-items: center;
}

.newsletter-choice > div > input {
    margin-right: 5px;
    background-color: grey;
}

.newsletter-first-name, .newsletter-last-name, .newsletter-email {
    width: 100%;
    background-color: black;
    padding: 10px 15px;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 14px;
    height: 60px;
}

.newsletter-email {
    /*margin-bottom: 25px;*/
}

.newsletter-first-name::placeholder, .newsletter-last-name::placeholder, .newsletter-email::placeholder {
    color: white;
    font-weight: bold;
    font-size: 14px;
}

.newsletter-field-description {
    color: grey;
    font-weight: bold;
    font-size: 12px;
}

.newsletter-section {
    margin-bottom: 25px;
}

.newsletter-button-container {
    display: block;
    margin: auto;
    text-align: center;
}

.newsletter-button {
    margin: 25px 0;
    padding: 5px 50px;
    font-weight: bold;
    color: black;
    background-color: #FFF280;
}

.newsletter-button:disabled {
    background-color: grey;
}

.newsletter-error-hide, .newsletter-error-show {
    /*margin-bottom: 10px;*/
    font-size: 14px;
}

.newsletter-error-hide {
    visibility: hidden;
}

.newsletter-error-show {
    color: red;
}

.newsletter-success {
    margin-top: 25px;
    color: #FFF280;
    font-size: 14px;
}

.newsletter-thanks {
    font-size: 20px;
}
