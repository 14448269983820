.analytic-table-header > th,
.analytic-table-row > td {
  border: none !important;
  color: white;
  font-weight: bold;
  font-size: 10px;
}

.analytic-table-row:nth-child(odd) {
  background-color: #2d2d2e;
}

.analytic-table-header > th:hover {
  cursor: pointer;
}

/* customizing CSS for the list pagination */
.react-bootstrap-table-pagination > div {
  flex: 0 0 0% !important;
}

.page-link {
  color: white !important;
  background-color: transparent !important;
  border: none !important;
}

ul.pagination > li.active {
  text-decoration: underline;
  font-weight: bold;
  vertical-align: bottom;
  /* transform: scale(1.7); */
}

.btn-secondary {
  background-color: transparent !important;
  border: none !important;
}
.btn-secondary:hover {
  color: yellow !important;
}
/* END: customizing CSS for the list pagination */

@media only screen and (min-width: 992px) {
  .analytic-table-header > th,
  .analytic-table-row > td {
    font-size: 11px;
  }
}

@media only screen and (min-width: 1100px) {
  .analytic-table-header > th,
  .analytic-table-row > td {
    font-size: 12px;
  }
}
