.search-form {
    text-align: center;
}

.search-input {
    max-width: 800px;
    height: 75px;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #404040;
    padding-left: 50px;
    background-image: url(https://cdn4.iconfinder.com/data/icons/round-mobile-ui-set/512/letter-message-email-mail-20.png);
    background-position: 10px center;
    background-repeat: no-repeat;
}


.search-input::placeholder {
    color: white;
    font-size: 20px;
}

.search-name-input {
  width: 33%;
  display: inline-block;
}

.card-search-about-results {
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0 50px;
}

.active-paginate {
    text-decoration: underline;
}

.pagination-link, .pagination-prev, .pagination-next, .card-search-top {
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
}

.pagination-link:hover, .pagination-prev:hover, .pagination-next:hover, .card-search-top:hover {
    color: #ffffff;
    cursor: pointer;
}

.pagination > li {
    padding: 0 5px;
}

.card-search-top-container {
    text-align: right;
}

@media only screen and (max-width: 992px) {
    .search-input {
        max-width: unset;
    }
}
