.positive-col {
    color: green !important;
}

.negative-col {
    color: red !important;
}

/*.table-dropup, .table-dropdown {*/
/*    border-top: 0;*/
/*    content: "";*/
/*}*/

/*.caret {*/
/*    width: 0;*/
/*    height: 0;*/
/*    border-left: 5px solid transparent;*/
/*    border-right: 5px solid transparent;*/
/*    border-top: 5px solid white;*/
/*}*/

.analytic-table-header > th, .analytic-table-row > td {
    border: none !important;
    color: white;
    font-weight: bold;
    font-size: 10px;
}

.analytic-table-row:nth-child(odd) {
    background-color: #2d2d2e;
}

.analytic-table-header > th:hover {
    cursor: pointer;
}

@media only screen and (min-width: 992px) {
    .analytic-table-header > th, .analytic-table-row > td {
        font-size: 11px;
    }
}

@media only screen and (min-width: 1100px) {
    .analytic-table-header > th, .analytic-table-row > td {
        font-size: 12px;
    }
}