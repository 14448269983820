.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  color: white !important;
  max-width: 2500px;
  margin: auto !important;
}

.slab-logo {
  font-family: 'Roboto', 'Bebas Neue', serif;
  background-color: lightgrey;
  color: black;
  font-weight: bolder;
}

.watch-logo {
  font-family: 'Roboto', 'Bebas Neue', serif;
  font-weight: bolder;
}

.announcement {
  background-color: green;
  padding: 25px;
  color: white;
}

.announcement-link {
  color: white;
  font-weight: bold;
}

.main-section {
  /*overflow-x: scroll;*/
  margin-top: 50px;
}

.tracker-table-header > th, .tracker-table-row > td {
  font-size: 0.9rem;
}

.tracker-table-header {
  line-height: 0.5rem;
  white-space: nowrap;
}

.tracker-table-row {
  line-height: 0px;
  white-space: nowrap;
}

.tracker-table-row:nth-child(odd) {
  background-color: #f4f4f4;
}

.tracker-table-row > td > a {
  color: #004f88;
  font-size: 0.9rem;
}

.tracker-table-row > td {
  border: none !important;
}

.tracker-link {
  color: #004f88;
}

.tracker-results-header {
  margin: 20px 0 10px 0;
}

th {
  border: none !important;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.margin-top25 {
  margin-top: 25px;
}
