.unusual-title {
  font-size: 1.7rem;
  margin-bottom: 0;
}

.unusual-title-subtext {
  font-size: 0.857rem;
  margin-bottom: 15px;
}

.nav-tabs {
  margin-top: 10px;
}

.nav-tabs > .nav-link {
  border-top: 3px solid #ccc !important;
  color: white !important;
}

.nav-tabs > .nav-link.active {
  border-top: 3px solid #2284dd !important;
  color: black !important;
}

.nav-tabs > a {
  font-size: 0.9rem;
  padding: 4px 7px;
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
  margin-right: 7px;
  width: 100px;
  text-align: center;
}

.last-updated,
.analytic-disclaimer {
  font-size: 12px;
  font-weight: bold;
  color: grey;
}

/* customizing CSS for the list pagination */
.react-bootstrap-table-pagination > div {
  flex: 0 0 0% !important;
}

.page-link {
  color: white !important;
  background-color: transparent !important;
  border: none !important;
}

ul.pagination > li.active {
  text-decoration: underline;
  font-weight: bold;
  vertical-align: bottom;
  /* transform: scale(1.7); */
}

.btn-secondary {
  background-color: transparent !important;
  border: none !important;
}
.btn-secondary:hover {
  color: yellow !important;
}
/* END: customizing CSS for the list pagination */
