.card-search-title-container, .card-search-price-container {
    height: 50%;
}

.card-search-image {
    width: 100%;
}

.card-search-title {
    font-size: 20px;
}

.card-search-arrow {
    height: 26px;
}

.card-search-separator {
    border-color: #313131;
    margin: 20px 0;
}

.card-search-details {
    align-self: flex-end;
    display: flex;
    align-items: center;
}

.card-search-detail-header {
    font-size: 14px;
    color: #b2b2b2;
}

.card-search-price {
    color: white;
    font-size: 34px;
    font-weight: bold;
}

.card-search-price-change {
    font-size: 20px;
    color: #b2b2b2;
    font-weight: 500;
}

.card-search-positive, .card-search-negative {
    font-size: 34px;
    font-weight: bold;
}

.card-search-positive {
    color: #2e8c38;
}

.card-search-negative {
    color: red;
}

.card-search-percent-container {
    display: flex;
    align-items: center;
}

.card-search-item:hover {
    cursor: pointer;
}

@media only screen and (max-width: 771px) {
    .card-search-title {
        font-size: 16px;
    }

    .card-search-price, .card-search-price-change, .card-search-positive, .card-search-negative {
        font-size: 14px;
    }

    .card-search-arrow {
        height: 14px;
    }

    .card-search-detail-header {
        font-size: 12px;
        display: inline;
        margin-right: 5px;
    }

    .card-search-price-details {
        display: inline;
    }
}
