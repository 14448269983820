.home-top-movers-container {
    margin-top: 50px;
    margin-bottom: 50px;
}
.home-top-movers-title {
    color: grey;
    font-weight: bold;
    margin-left: -15px;
}

@media only screen and (max-width: 771px) {
    .home-top-movers-second-row {
        display: none;
    }
}