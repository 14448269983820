.home-main-logo {
    max-width: 150px;
}

.home-main-text {
    text-align: center;
    color: white;
    font-size: 26px;
    font-family: 'HelveticaNeueLTPro-XBlkCn', 'Roboto', serif;
    font-weight: bolder;
}

.home-main-subtext {
    text-align: center;
    color: white;
    font-size: 10px;
    font-family: 'HelveticaNeueLTPro-Roman', 'Roboto', serif;
}

.home-main-card-spread {
    max-width: 600px;
    width: 100%;
    margin-top: 36px;
}

.home-input-container {
    text-align: center;
    margin: auto auto 15px;
    max-width: 800px;
    width: 100%;
}

.home-input,
.home-input::placeholder {
  font-weight: bold;
  color: white;
}

.home-input {
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #3b3939;
    padding: 15px 10px 15px 50px;
    background-image: url(https://cdn4.iconfinder.com/data/icons/round-mobile-ui-set/512/letter-message-email-mail-20.png);
    background-position: 10px center;
    background-repeat: no-repeat;
}

.home-input-button {
  padding: 15px 0px;
  width: 20%;
  background-color: #075467;
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: inline-block;
  vertical-align: top;
}

.home-error {
  color: red;
}

.home-main-svgbreaks {
  font-size: 24px;
}

.home-card {
  border: none;
  background-color: black;
  /*margin-top: 20px;*/
}

.home-card-image {
  width: 50%;
  margin-top: 10px;
}

.home-card-body {
    padding: 9px 0 9px 10px;
    text-align: center;
    background-color: #0b0b0b;
}

.home-card-text-container {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.home-card-container {
  padding-right: 5px !important;
  padding-left: 0 !important;
  padding-bottom: 5px !important;
}

.home-card-text {
    color: white;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 18px;
}

.home-card-change {
    color: grey;
    font-size: 14px;
    text-align: left;
    font-weight: bold;
}

.home-card-arrow-red, .home-card-arrow-green {
    font-weight: bold;
}

.home-card-arrow-red {
  color: red;
}

.home-card-arrow-green {
  color: green;
}

.home-percent-change-container {
    display: flex;
    align-items: center;
    line-height: normal;
}

.home-percent-change-container > img {
  margin-right: 5px;
  width: 15px;
}

.home-top-movers-top-text {
  display: flex;
  justify-content: space-between;
  color: grey;
  font-weight: bold;
}

.home-hottest-container {
  margin-top: 60px;
}

.home-hottest-container > div:first-child {
  padding-left: 0px;
  padding-right: 10px;
}

.home-hottest-container > div:nth-child(2) {
  padding-left: 0px;
  padding-right: 5px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* dowload App part */
.download-app-btn {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: inline-block;
  white-space: nowrap;
  white-space: -webkit-nowrap;
  width: 250px;
  height: 35px;
  margin-top: 50px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  background-color: #fcbc0e;
  border: none;
  border-radius: 3px;
  outline: none;
}
.download-app-btn:hover {
  background-color: #fcbd0ea8;
}
.btn-text {
  color: black;
  text-decoration: none;
  outline: none;
}
.apple-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 3px;
  vertical-align: sub;
  background-image: url("../../assets/icon-apple.svg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 771px) {
  .home-main-logo {
    width: 100%;
  }

  .home-main-svgbreaks {
    display: block;
  }

  .home-hottest-container > div:first-child {
    padding-right: 5px;
  }

  .home-input-container {
    width: 100%;
  }
}