.best-offer-more-features-image {
    width: 100%;
}

.best-offer-more-features-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    color: white;
    padding: 40px;
    width: 80%;
}

.best-offer-more-features-coming-soon {
    font-size: 36px;
    font-weight: bold;
}

.best-offer-more-features-subtext {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.best-offer-more-features-subtext > span {
    color: #FFF280;
}


.best-offer-more-features-container {
    position: relative;
    text-align: center;
}

.best-offer-more-features-button {
    background-color: #FFF280;
    color: black;
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
    display: block;
}

@media only screen and (max-width: 991px) {
    .best-offer-more-features-coming-soon {
        font-size: 20px;
        font-weight: bold;
    }

    .best-offer-more-features-subtext {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 544px) {
    .best-offer-more-features-coming-soon {
        font-size: 16px;
        font-weight: bold;
    }

    .best-offer-more-features-subtext {
        font-size: 10px;
        font-weight: bold;
        margin-bottom: 5px;
    }
}
