.footer-extension {
    color: #FFF280;
    font-size: 12px;
    display: flex;
}

.footer-extension-icon {
    margin-left: 5px;
}

.footer-links1 {
    margin-top: 20px;
}

.footer-link {
    color: white;
    font-weight: bold;
    text-decoration: underline;
    margin-right: 10px;
    font-size: 12px;
    display: inline-block;
}


.footer-newsletter-text {
    color: #FFF280;
    text-align: right;
    font-weight: bold;
    font-size: 14px;
}

.footer-newsletter-button-web, .footer-newsletter-button-mobile {
    background-color: #FFF280;
    color: black;
    font-weight: bold;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
}

.footer-newsletter-button-mobile {
    display: none;
}

.footer-company {
    color: white;
    font-weight: bold;
    text-decoration: underline;
    font-size: 12px;
    margin-bottom: 20px;
    text-align: right;

}

.footer-container {
    background-color: black;
    padding-top: 40px;
}

.footer-logo {
    width: 100%;
    max-width: 300px;
}

.footer-right-side {
    display: flex;
    flex-direction: column;
}

.footer-row-2 {
    text-align: right;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 991px) {
    .footer-newsletter-button-mobile {
        display: block;
    }

    .footer-newsletter-button-web {
        display: none;
    }
}

@media only screen and (max-width: 501px) {
    .footer-link, .footer-newsletter-text {
        margin: 5px 0;
    }

    .footer-link, .footer-newsletter-text, .footer-company {
        font-size: 10px;
    }
}
