/*.search-transactions-title-container, .search-transactions-price-container, .search-transactions-date-container {*/
/*    height: 50%;*/
/*}*/

.search-transactions-image {
    width: 85px;
}

.search-transactions-title {
    font-size: 20px;
    font-weight: bold;
}

.search-transactions-arrow {
    height: 26px;
}

.search-transactions-separator {
    border-color: #313131;
    margin: 20px 0;
}

.search-transactions-details {
    align-self: flex-end;
    display: flex;
    align-items: center;
}

.search-transactions-detail-header {
    font-size: 14px;
    color: #b2b2b2;
}

.search-transactions-price {
    color: #63be54;
    font-size: 24px;
    font-weight: bold;
}

.search-transactions-price-change {
    font-size: 20px;
    color: #b2b2b2;
    font-weight: 500;
}

.search-transactions-date {
    text-align: left;
    font-size: 14px;
    color: #b2b2b2;
    font-weight: bold;
}

.search-transactions-positive, .search-transactions-negative {
    font-size: 34px;
    font-weight: bold;
}

.search-transactions-positive {
    color: #2e8c38;
}

.search-transactions-negative {
    color: red;
}

.search-transactions-percent-container {
    display: flex;
    align-items: center;
}

.search-transactions-item:hover {
    /* cursor: pointer; */
}

@media only screen and (max-width: 771px) {
    .search-transactions-title {
        font-size: 16px;
    }

    .search-transactions-price, .search-transactions-price-change, .search-transactions-positive, .search-transactions-negative {
        font-size: 14px;
    }

    .search-transactions-arrow {
        height: 14px;
    }

    .search-transactions-detail-header {
        font-size: 12px;
        display: inline;
        margin-right: 5px;
    }

    .search-transactions-price-details {
        display: inline;
    }
}
