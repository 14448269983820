.price-tracker-container {
  margin-bottom: 30px;
}

.price-tracker-image {
  width: 100%;
}

.price-tracker-title {
  font-weight: bold;
  font-size: 36px;
}

.price-tracker-analytic-section {
  margin-top: 30px;
}

.price-tracker-separator {
  background-color: white;
  margin: 20px 0;
}

.price-tracker-analytic-title {
  font-weight: bold;
  font-size: 12px;
  margin-top: 5px;
}

.price-tracker-analytic {
  font-weight: bold;
  font-size: 24px;
}

.price-tracker-bottom-analytic {
  font-size: 14px;
  font-weight: bold;
}

.price-tracker-tag-title {
  font-weight: bold;
  font-size: 12px;
}

.price-tracker-tag {
  text-decoration: underline;
  font-weight: bold;
  margin-right: 3px;
  font-size: 12px;
}

.recharts-tooltip-wrapper {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5%;
}

.price-tracker-filter-buttons {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.price-tracker-filter-buttons > li {
  display: inline-block;
  width: 33%;
}
.price-tracker-filter-buttons > li > button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: white !important;
  border: none;
  outline: none;
  font-weight: bold;
}
.price-tracker-filter-buttons > li:hover > button {
  color: yellow !important;
}

.recharts-tooltip-wrapper {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5%;
}

.price-tracker-filter-buttons {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
}
.price-tracker-filter-buttons > li {
    display: inline-block;
    width: 33%;
}
.price-tracker-filter-buttons > li > button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: white !important;
    border: none;
    outline: none;
    font-weight: bold;
}
.price-tracker-filter-buttons > li:hover > button {
    color: yellow !important;
}

@media only screen and (max-width: 771px) {
  .price-tracker-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .price-tracker-title {
    font-size: 16px;
  }

  .price-tracker-analytic-title {
    font-size: 9px;
  }

  .price-tracker-analytic {
    font-size: 12px;
  }
}
