.home-table,
.home-table-header > th,
.home-table-row > td {
  border: none !important;
}

.home-table-header > th,
.home-table-row > td {
  font-weight: bold;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.home-table-header > th,
.home-see-all {
  color: grey;
}

.home-see-all {
  font-style: italic;
  text-decoration: underline;
}

.home-table-row > td:nth-child(1) {
  color: white;
}

.home-table-row:nth-child(odd) {
  background-color: #2b2a2a;
}

.home-table-row:last-child {
  background-color: initial;
}

.positive-percent {
  color: green;
}

.negative-percent {
  color: red;
}

.home-table-row > td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-hottest-header-title {
    font-size: 20px;
}

.home-hottest-header-change {
    font-size: 12px;
}
