.navbar-color {
    color: white !important;
    font-weight: bold !important;
}

.navbar-color > a {
    color: white !important;
}

.navbar-right-links {
    margin-left: auto !important;
    margin-right: 50px;
}

.csh-logo {
    width: 100px;
    height: auto;
}

.nav-logo {
    padding: unset;
    font-size: unset;
    text-align: center;
}

.nav-logo > img {
    width: 100%;
}

.navbar-link {
    font-size: 16px;
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-right: 10px;
    color: white !important;
    font-weight: bold;
}

.navbar-link:last-child {
    margin-right: 0px;
}

.nav-extension-mobile {
    display: none;
}

.nav-extension-web, .nav-extension-mobile {
    border: 1px solid #FFF280;
    border-radius: 3px;
}

.nav-extension-web > a, .nav-extension-mobile > a, .navbar-link > a {
    color: white !important;
    font-weight: bold;
}

.navbar-css {
    background-color: black;
    text-align: center;
    /*font-family: 'Bebas Neue', 'Roboto';*/
    font-family: Roboto, Sans-serif, serif;
}

.navbar > button {
    background-color: white !important;
}

.active-nav-link {
    border-bottom: 2px solid #FFF280;
}

@media only screen and (max-width: 1200px) {
    .navbar-link, .nav-extension-web, .nav-extension-mobile, .dropdown-menu > a {
        font-size: 12px;
    }

    .dropdown-menu > a {
        text-align: center;
    }
}

@media only screen and (min-width: 1201px) {
    .navbar-link {
        font-size: 15px;
    }
}

@media only screen and (max-width: 991px) {
    .nav-extension-web {
        display: none;
    }

    .nav-extension-mobile {
        display: block;
    }

    .nav-link {
        margin-right: 0px !important;
    }
}

@media only screen and (max-width: 501px) {
    .nav-extension-mobile {
        max-width: 33%;
        font-size: 9px;
    }

    .nav-logo {
        max-width: 33%;
    }
}
