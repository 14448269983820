.how-it-works-container {
    color: white;
    font-weight: bold;
}

.how-it-works {
    font-size: 36px;
    padding-top: 25px;
}

.how-it-works-browser {
    color: #FFF280;
    text-decoration: underline;
}

.how-it-works-browser:hover {
    color: #FFF280;
}

.how-it-works-video {
    margin-top: 50px;
    height: 500px;
    width: 100%;
}
